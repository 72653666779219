import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions - Recurrent or Metastatic or Locally Advanced Cutaneous Squamous Cell Carcinoma (cSCC) - HCP`,
    keywords: `recurrent or metastatic or locally advanced cutaneous squamous cell carcinoma adverse reactions`,
    description: `Learn about selected immune-mediated adverse reactions and the most common adverse reactions to KEYTRUDA® (pembrolizumab) in patients with recurrent or metastatic cutaneous squamous cell carcinoma (cSCC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with recurrent or metastatic cutaneous squamous cell carcinoma (cSCC) or locally advanced cSCC that is not curable by surgery or radiation."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma/","@type":"MedicalWebPage","name":"Safety Data from KEYNOTE⁠-⁠629","description":"Adverse reactions occurring in patients with recurrent or metastatic cSCC or locally advanced cSCC were similar to those occurring in 2,799 patients with melanoma or NSCLC treated with KEYTRUDA as a single agent."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cutaneous-squamous-cell-carcinoma/","@type":"MedicalAudience","audienceType":"health care professional","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 13;
const jobCode = jobCodes[13].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Safety Data from KEYNOTE&#8288;-&#8288;629' }
];

const Keynote629defintions = 'R/M = recurrent or metastatic; NSCLC = non–small cell lung cancer.';

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock definitions="NSCLC = non–small cell lung cancer.">
                                <p>Among the 159 patients with advanced cSCC (recurrent or metastatic or locally advanced disease) enrolled in KEYNOTE&#8288;-&#8288;629, the median duration of exposed to KEYTRUDA was 6.9 months (range 1 day to 28.9 months). Adverse reactions occurring in patients with recurrent or metastatic cSCC or locally advanced cSCC were similar to those occurring in 2,799 patients with melanoma or NSCLC treated with KEYTRUDA as a single agent.</p>
                                <p>Laboratory abnormalities (Grades 3–4) that occurred at a higher incidence in patients with recurrent or metastatic cSCC or locally advanced cSCC included lymphopenia (10%) and decreased sodium (10%).</p>
                            </TextBlock>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
